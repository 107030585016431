.RelExpr .clicked,
.RelExpr.clicked {
  background: #ff3;
  cursor: pointer;
}

.RelExpr .hovering,
.RelExpr.hovering {
  background: #ffffb8;
  cursor: pointer;
}
