/* stylelint-disable */
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}
/* stylelint-enable */

.footer {
  padding: 20px;
  font-size: 0.75em;
}

.button {
  border-radius: 15px;
  font-weight: bold;
  background-color: lightgray; /* Green */
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
  margin-left: 0;
  margin-bottom: 20px;
  font-size: 1em;
  cursor: pointer;
}

.footer .button {
  float: right;
}

.email {
  float: left;
}

.relExprContainer {
  margin-top: 2em;
}

.relExprContainer .expr {
  font-family: 'Fira Code', 'Fira Mono', monospace;
  font-display: swap;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  padding: 8px;
  border-radius: 10px;
}

.relExprContainer .toggle {
  float: right;
  font-size: 0.75em;
  position: relative;
  top: -1.75em;
}

.relExprContainer .toggle input {
  vertical-align: middle;
}

.bottomLeftContainer {
  width: 100%;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dataContainer {
  background-color: white;
  border-radius: 10px;
  padding: 0;
}

h2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 600px) {
  h2 {
    padding-left: 24px;
  }
}
