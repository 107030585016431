/* If all Google Fonts are removed, remove preconnect from index.html */
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

h4 {
  margin-top: 0;
  margin-left: 3px;
  margin-bottom: 8px;
}

.SqlEditor {
  background-image: radial-gradient(circle, #f2f2f2, #d9d9d9);
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.editor {
  border-radius: 8px;
  background-color: white;
}

.SqlEditor .error {
  font-weight: bold;
  padding: 0.1em;
}
